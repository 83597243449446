export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();
      config.headers[
        "ARTGIDI-API-SECRET"
      ] = `0bTP9CQOOLUvOgsG4xx34FDtrtSzj4Ohy7hvAdISlAP4rSup5hjAXgUPmLbAsTe3`;
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
        // config.headers.Authorization = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmFydGdpZGkuY29tXC9hcGlcL2FkbWluXC9sb2dpbiIsImlhdCI6MTYwNzY5MjI0MCwiZXhwIjoxNjEwMzIwMjQwLCJuYmYiOjE2MDc2OTIyNDAsImp0aSI6IklWQlN0ODZodjNLYVJqWkgiLCJzdWIiOjEsInBydiI6ImNmMjg0YzJiMWUwNmYzM2MyNmJkNTc5NzU2NmQ5ZmQ3NGJlMTFiZjUifQ.dnXV5tFN0_8vsjmkidsUNs-ubTf51L9LxCP_eF7xglU`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
}
