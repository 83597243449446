import React from "react";
import { Dashboard as Test } from "../../_metronic/_partials";
import Dashboard from "../pages/Dashbord";

export function DashboardPage() {
  return (
    <div className="">
      <Dashboard />
    </div>
  );
}
