import axios from "axios";
export const ADMINS_URL = `${process.env.REACT_APP_URL}`;
export const UPDATE_PROFILE = "edit_admin/";
export const UPDATE_PASSWORD = "update_password/";

export function updateAdmin(admin) {
  return axios.post(`${ADMINS_URL + UPDATE_PROFILE}${admin.id}`, admin);
}

export function updatePassword(admin) {
  return axios.post(ADMINS_URL + UPDATE_PASSWORD, admin);
}
