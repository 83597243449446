/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        <li
          className={`menu-item ${getMenuItemActive("/profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/profile">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">My profile</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/builder", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Layout Builder</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Management</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Management */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/management/admins")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/admins">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Admins</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/management/withdrawals"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/auction_artists">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Auctions Artists</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/withdrawals"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/auctions">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Auctions</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/withdrawals"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/events">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Events</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/customers")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/customers">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/escrow")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/escrow">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Escrow Management</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/management/transactions"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/transactions">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Transactions</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/bidding-transactions"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/bidding-transactions">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Bidding Transactions</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/management/resale")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/resale">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Art Resale</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/management/art_resale")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/art_resale">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Art Resale Delux</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/management/catalogue")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/catalogue">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Art Catalogues</span>
          </NavLink>
        </li>
        <li
            className={`menu-item ${getMenuItemActive("/management/reports")}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/reports">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Art Reports</span>
          </NavLink>
        </li>
        <li
            className={`menu-item ${getMenuItemActive("/management/offers")}`}
            aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/offers">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Art Offers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/dynamic_url"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/dynamic_url">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Dynamic Urls</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item ${getMenuItemActive("/management/orders")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/orders">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">All Orders</span>
          </NavLink>
        </li> */}

        <li
          className={`menu-item ${getMenuItemActive("/management/shipping")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/shipping">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Shipping Details</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/vat")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/vat">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Value Added Tax</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/exporttax")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/exporttax">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Export Tax</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/withdrawals"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/subscribers">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Subscribers</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/exchangerate"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/exchangerate">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Exchange Rate</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/management/commission")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/commission">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Artgidi Commission</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/management/withdrawals"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/withdrawals">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Withdrawals</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/management/featured")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/featured">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Featured</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            "/management/printing-price"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/printing-price">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Printing Price</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive("/management/banners")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management/banners">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text">Banners</span>
          </NavLink>
        </li>

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Management</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Management</span>
                </span>
              </li>

              {/*begin::2 Level*/}

        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/management/products"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/management/products">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Products</span>
                </NavLink>
              </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}

        {/*end::2 Level*/}
        {/* </ul>
          </div>
        </li> */}
        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Promotions</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Option.svg")} />
            </span>
            <span className="menu-text">Promotions</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Promotions</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/promotions/discounts"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/promotions/discounts">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Discounts</span>
                </NavLink>{" "}
              </li> */}
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/promotions/promotions"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/promotions/promotions">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Promotions</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
