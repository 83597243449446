import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Axios from "axios";

class TransactionsPerCustomer extends React.Component {
  state = {
    transactions: "",
    customers: "",
  };

  componentDidMount() {
    this.get_data();
  }
  get_data = async () => {
    const resp = await Axios.get(
      `${process.env.REACT_APP_URL}dashboard/get_transactions_by_customers?status=active&start_time=2019-10-12`
    );
    // console.log(resp.data);
  };

  get_series(result) {
    let initial = [];
    let transactions = [];
    result.filter((role) => {
      let each_array = role.amount_paid;
      let transaction = role.curr_transaction;
      initial.push(each_array);
      transactions.push(transaction);
    });
    this.setState({
      customers: initial,
      transactions: transactions,
    });
  }

  render() {
    const options = {
      chart: {
        zoomType: "x",
      },
      title: {
        text: "Transactions Per Customer",
      },
      xAxis: {
        categories: this.state.transactions,
      },
      yAxis: {
        title: {
          text: "Customers",
        },
      },
      rangeSelector: {
        enabled: false,
      },
      series: [
        {
          type: "line",
          data: this.state.customers,
          allowPointSelect: true,
        },
      ],
      credits: {
        enabled: false,
      },
    };

    return (
      <div className="mt-5 mb-5">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      </div>
    );
  }
}
export default TransactionsPerCustomer;
