import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import Axios from "axios";

class TransactionWidget extends React.Component {
  state = {
    transactions: "",
    days: "",
  };

  componentDidMount() {
    this.get_data();
  }
  get_data = async () => {
    const resp = await Axios.get(
      `${process.env.REACT_APP_URL}dashboard/get_transactions_by_period?status=active&start_time=2019-10-12`
    );
    this.get_series(resp.data.data);
  };

  get_series(result) {
    let initial = [];
    let days = [];
    result.filter((role) => {
      let each_array = role.amount_paid;
      let day = role.curr_day;
      initial.push(each_array);
      days.push(day);
    });
    this.setState({
      transactions: initial,
      days: days,
    });
  }

  render() {
    // console.log(this.state.transactions);
    const options = {
      chart: {
        zoomType: "x",
      },
      title: {
        text: "Transactions",
      },
      xAxis: {
        categories: this.state.days,
      },
      yAxis: {
        title: {
          text: "Amount",
        },
      },
      rangeSelector: {
        enabled: false,
      },

      series: [
        {
          type: "line",
          data: this.state.transactions,
          allowPointSelect: true,
        },
      ],
      credits: {
        enabled: false,
      },
    };
    return (
      <div className="mt-5 mb-5">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"stockChart"}
          options={options}
        />
      </div>
    );
  }
}

export default TransactionWidget;
