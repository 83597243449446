import React, { useState } from "react";
import "../../_metronic/_assets/css/ProfilePage.css";
import { Tabs, Tab } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./ProfilePage/redux/ProfilePageActions";
import PasswordChange from "./ProfilePage/components/passwordChange";

export function ProfilePage() {
  const { user } = useSelector((state) => state.auth);

  // const dispatch = useDispatch();

  // const saveAdmin = (admin) => {
  //   // server request for updating admin
  //   dispatch(actions.updateAdmin(admin));
  // };

  const dispatch = useDispatch();
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.admins.actionsLoading,
    }),
    shallowEqual
  );

  const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    return {
      value,
      setValue,
      bind: {
        onChange: (event) => {
          setValue(event.target.value);
        },
      },
    };
  };

  const { value: firstname, bind: bindFirstName } = useInput("");
  const { value: lastname, bind: bindLastName } = useInput("");
  const { value: phone, bind: bindPhone } = useInput("");

  const handleForm = (event) => {
    event.preventDefault();
    // const { firstname, lastname, role, phone, email } = e.target.elements;

    const role = () => {
      if ((user.is_super_admin = "1")) {
        return "super_admin";
      } else if (user.is_admin === "1") {
        return "admin";
      } else {
        return "audit";
      }
    };
    const admin = {
      id: user.id,
      email: user.email,
      role: role(),
      firstname: firstname,
      lastname: lastname,
      phone: phone,

      // event.currentTarget.firstname.value,
      // event.currentTarget.lastname.value,
      // event.currentTarget.role.value,
      // event.currentTarget.phone.value,
      // event.currentTarget.email.value,
    };
    // console.log(admin);
    dispatch(actions.updateProfile(admin));
  };

  return (
    <div id="profile">
      <Tabs defaultActiveKey="personal_info" id="uncontrolled-tab-example">
        {/* Begin personal info section */}
        <Tab eventKey="personal_info" title="Personal Info">
          <div className="">
            <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
              <div className="row">
                <div className="col-xl-12">
                  <div className="kt-portlet">
                    {actionsLoading && (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    )}
                    <form
                      className="kt-form kt-form--label-right"
                      onSubmit={handleForm}
                    >
                      <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                          <h3 className="kt-portlet__head-title mr-">
                            Personal Information <br />
                            <small>update your personal informaiton</small>
                          </h3>
                        </div>

                        <div className="kt-form__actions">
                          <div className="row">
                            <div className=" col-12 mt-3">
                              <button
                                type="submit"
                                className="btn btn-success ml-auto mr-4"
                              >
                                Submit
                              </button>
                              &nbsp;
                              <button
                                type="reset"
                                className="btn btn-secondary ml-auto mr-4"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="kt-portlet__body">
                        <div className="kt-section kt-section--first">
                          <div className="kt-section__body">
                            <div className="row">
                              <label className="col-xl-3" />
                              <div className="col-lg-9 col-xl-6">
                                <h3 className="kt-section__title kt-section__title-sm">
                                  My Info:
                                </h3>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                First Name
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  name="firstname"
                                  className="form-control"
                                  type="text"
                                  defaultValue={user.firstname}
                                  {...bindFirstName}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                Last Name
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  name="lastname"
                                  className="form-control"
                                  type="text"
                                  defaultValue={user.lastname}
                                  {...bindLastName}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                Role
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <input
                                  name="role"
                                  className="form-control"
                                  type="text"
                                  defaultValue={
                                    user.is_super_admin == "1"
                                      ? "Super Administrator"
                                      : user.is_admin == "1"
                                      ? "Administrator"
                                      : "Auditor"
                                  }
                                  disabled
                                />
                                <span className="form-text text-muted">
                                  If you want your invoices addressed to a
                                  company. Leave blank to use your full name.
                                </span>
                              </div>
                            </div>
                            <div className="row">
                              <label className="col-xl-3" />
                              <div className="col-lg-9 col-xl-6">
                                <h3 className="kt-section__title kt-section__title-sm">
                                  Contact Info:
                                </h3>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                Contact Phone
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="fa fa-phone" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="phone"
                                    className="form-control"
                                    defaultValue={user.phone}
                                    placeholder="Phone"
                                    {...bindPhone}
                                  />
                                </div>
                                <span className="form-text text-muted">
                                  We'll never share your email with anyone else.
                                </span>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-xl-3 col-lg-3 col-form-label">
                                Email Address
                              </label>
                              <div className="col-lg-9 col-xl-6">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text">
                                      <i className="fa fa-at" />
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={user.email}
                                    placeholder="Email"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Tab>
        {/* end profile information section */}

        {/* begin change password section */}
        <Tab eventKey="password" title="Change Password">
          <PasswordChange />
        </Tab>
        {/* End change password section */}
      </Tabs>
    </div>
  );
}
