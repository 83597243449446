import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Axios from "axios";
import { Link } from "react-router-dom";

class Latest_artworks extends React.Component {
  state = {
    artworks: "",
  };
  componentDidMount() {
    Axios.get(
      `${process.env.REACT_APP_URL}get_all_artworks?count=4&status=pending`
    ).then((resp) => {
      this.setState({
        artworks: resp.data.data,
      });
    });
  }
  render() {
    const artworks = this.state.artworks;
    // console.log(artworks);
    return (
      <div>
        <div className="mt-5 mb-5">
          <div className={`card card-custom `}>
            {/* Head */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Latest Artworks Uploaded
                </span>
              </h3>
            </div>
            {/* Body */}
            <div className="card-body pt-3 pb-0">
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "50px" }} />
                      <th className="p-0" style={{ minWidth: "125px" }} />
                      <th className="p-0" style={{ minWidth: "100px" }} />
                      <th className="p-0" style={{ minWidth: "125px" }} />
                      <th className="p-0" style={{ minWidth: "110px" }} />
                    </tr>
                  </thead>
                  <tbody>
                    {artworks &&
                      Object.keys(artworks).map((artwork) => {
                        const each_artwork = artworks[artwork];
                        // console.log(each_artwork);
                        return (
                          <tr>
                            <td className="pl-0 py-4">
                              <div className="symbol symbol-50">
                                <span className="symbol-label">
                                  <img
                                    src={
                                      `${process.env.REACT_APP_URL_IMAGE}assets/image/artworks/` +
                                      each_artwork.id +
                                      "/" +
                                      each_artwork.artwork_image
                                    }
                                    className="img-fluid"
                                    alt=""
                                  />
                                </span>
                              </div>
                            </td>

                            <td className="pl-0">
                              <a
                                href="#"
                                className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              >
                                {each_artwork.artwork_title
                                  ? each_artwork.artwork_title
                                  : "Untitled"}
                              </a>
                              <div>
                                <span className="font-weight-bolder">
                                  {each_artwork.email}
                                </span>{" "}
                                <a
                                  className="text-muted font-weight-bold text-hover-primary"
                                  href="#"
                                >
                                  {each_artwork.quantity}
                                </a>
                              </div>
                            </td>
                            <td className="text-right">
                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {each_artwork.artwork_currency === "naira"
                                  ? "₦"
                                  : each_artwork.artwork_currency === "pounds"
                                  ? "£"
                                  : each_artwork.artwork_currency === "euro"
                                  ? "€"
                                  : "$"}
                                {each_artwork.artwork_price}
                              </span>
                            </td>
                            <td className="text-right">
                              <span className="text-muted font-weight-500">
                                {each_artwork.address}
                              </span>
                            </td>
                            <td className="text-right">
                              <Link
                                to={"/management/catalogue/" + each_artwork.id}
                                className="text-primary font-weight-bolder"
                              >
                                <i className="fa fa-eye"></i> View Artwork
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Latest_artworks;
