import axios from "axios";

export const API_URL = process.env.REACT_APP_URL;
// export const API_URL = "http://localhost:8000/api/admin/";

export const LOGIN_URL = "login";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "me";

export function login(email, password) {
  return axios.post(API_URL + LOGIN_URL, { email, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(API_URL + ME_URL);
}
