import React from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

function Action(id) {
  return (
    <div>
      <Link
        title="View Transaction"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        to={"/management/transactions/" + id}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
        </span>
   
      </Link>
    </div>
  );
}

export default Action;
