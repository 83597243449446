import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Axios from "axios";
import Action from "../../../../modules/Management/pages/TDResolutions/action";

export default class RecentWidget extends React.Component {
  state = {
    transactions: "",
  };

  componentDidMount() {
    this.get_data();
  }

  get_data = async () => {
    const resp = await Axios.get(
      `${process.env.REACT_APP_URL}get_transactions?count=5`
    );
    this.setState({
      transactions: resp.data.transactions,
    });
  };
  render() {
    const transactions = this.state.transactions;
    const columns = [
      { dataField: "transaction_ref", text: "TXN" },
      { dataField: "payment_currency", text: "currency" },
      { dataField: "total_amount", text: "Amount" },
      { dataField: "created_at", text: "Date Created" },
      { dataField: "payment_status", text: "Status" },
      { dataField: "id", text: "Actions", formatter: Action },
    ];
    return (
      <div className="mt-5 mb-5">
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <div className="card-title">
              <h3 className="card-label">Recent Transactions</h3>
            </div>
          </div>
          <div className="card-body">
            {/*begin: Datatable*/}
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center mt-5 mb-5"
              id="kt_datatable_2"
              keyField="transaction_ref"
              bootstrap4
              remote
              data={transactions}
              columns={columns}
              pagination={paginationFactory()}
            />

            {/*end: Datatable*/}
          </div>
        </div>
        {/*end::Card*/}
      </div>
    );
  }
}
