import * as requestFromServer from "./ProfilePageCRUD";
import { ProfilePageSlice, callTypes } from "./ProfilePageSlice";

const { actions } = ProfilePageSlice;

export const updateProfile = (admin) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAdmin(admin)
    .then((response) => {
      dispatch(actions.profileUpdated({ admin }));
      alert(response.data.message);
    })
    .catch((error) => {
      error.clientMessage = "Can't update admin";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      alert("Can't update admin");
    });
};

export const updatePassword = (admin) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePassword(admin)
    .then((response) => {
      // dispatch(actions.profileUpdated({ admin }));
      alert(response.data.message);
    })
    .catch((error) => {
      console.log(error);
      // error.clientMessage = "Can't update password";
      // alert("Can't update password");
      // dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
