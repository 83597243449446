import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ProfilePageActions";

export default function PasswordChange() {
  // const { user } = useSelector((state) => state.auth);

  //redux

  const dispatch = useDispatch();
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.admins.actionsLoading,
    }),
    shallowEqual
  );

  //gets user input
  const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    return {
      value,
      setValue,
      bind: {
        onChange: (event) => {
          setValue(event.target.value);
        },
      },
    };
  };

  const { value: password, bind: bindPassword } = useInput("");
  const { value: newPassword, bind: bindNewPassword } = useInput("");
  const { value: confirmPassword, bind: bindConfimPassword } = useInput("");

  // handles form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const admin = {
      password: password,
      password_new: newPassword,
      password_new_confirmation: confirmPassword,
    };

    // console.log(admin);

    dispatch(actions.updatePassword(admin));

    document.getElementById("passwordForm").reset();
  };

  return (
    <>
      <div>
        <div className="">
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-portlet kt-portlet--height-fluid">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <form
                    className="kt-form kt-form--label-right"
                    onSubmit={handleSubmit}
                    id="passwordForm"
                  >
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                          Change Password <br />
                          <small>change or reset your account password</small>
                        </h3>
                      </div>

                      <div className="kt-form__actions">
                        <div className="row">
                          <div className="mt-3">
                            <button
                              type="submit"
                              className="btn btn-success ml-auto mr-4"
                            >
                              Change Password
                            </button>
                            &nbsp;
                            <button
                              type="reset"
                              className="btn btn-secondary ml-auto mr-4"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="kt-portlet__body">
                      <div className="kt-section kt-section--first">
                        <div className="kt-section__body">
                          <div className="row">
                            <label className="col-xl-3" />
                            <div className="col-lg-9 col-xl-6">
                              <h3 className="kt-section__title kt-section__title-sm">
                                Change Or Recover Your Password:
                              </h3>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Current Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <input
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="Current password"
                                {...bindPassword}
                              />
                              <a
                                href="#"
                                className="kt-link kt-font-sm kt-font-bold kt-margin-t-5"
                              >
                                Forgot password ?
                              </a>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              New Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <input
                                name="newPassword"
                                type="password"
                                className="form-control"
                                placeholder="New password"
                                {...bindNewPassword}
                              />
                            </div>
                          </div>
                          <div className="form-group form-group-last row">
                            <label className="col-xl-3 col-lg-3 col-form-label">
                              Verify Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                              <input
                                name="verifyPassword"
                                type="password"
                                className="form-control"
                                placeholder="Verify password"
                                {...bindConfimPassword}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
