import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionsLoading: false,
  adminForEdit: undefined,
  lastError: null,
  entities: null,
};

export const callTypes = {
  action: "action",
};

export const ProfilePageSlice = createSlice({
  name: "profiles",
  initialState: initialState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.errror = null;
      if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      }
    },

    //profile updated
    profileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        // console.log(entity);
        if (entity.id === action.payload.profiles.id) {
          return action.payload.profiles;
        }
        return entity;
      });
    },
  },
});
