import React, { Component } from "react";
import TransactionWidget from "../Components/SubComponents/Transactions";
import Latest_artworks from "../Components/SubComponents/latest-artworks";
export class Stats2 extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <TransactionWidget />
          </div>
          <div className="col-lg-6">
            <Latest_artworks />
          </div>
        </div>
      </div>
    );
  }
}

export default Stats2;
