import React, { Component } from "react";
import CustomerWidget from "../Components/SubComponents/customers";
import Latest_artworks from "../Components/SubComponents/latest-artworks";
import TransactionsPerCustomer from "./SubComponents/transactions-per-customer";

export class Stats3 extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <CustomerWidget />
          </div>
          <div className="col-lg-6">
            <TransactionsPerCustomer />
          </div>
        </div>
      </div>
    );
  }
}

export default Stats3;
