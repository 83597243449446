import React from "react";
import Highcharts from "highcharts";
import Axios from "axios";

class AllCustomers extends React.Component {
  state = {
    // series: [
    //   {
    //     name: "Customers",
    //     data: [],
    //   },
    // ],
    result: [],
    total_users: "",
    role: "",
    data: "",
  };

  highChartsRender() {
    Highcharts.chart({
      chart: {
        type: "pie",
        renderTo: "all-customers",
      },
      title: {
        // verticalAlign: "middle",
        // floating: true,
        text: "",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: "40%",
        },
      },
      series: [
        {
          name: "Customer",
          data: this.state.data,
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }

  componentDidMount() {
    this.get_users();
  }

  get_users = async () => {
    const resp = await Axios.get(
      `${process.env.REACT_APP_URL}dashboard/get_users_by_category`
    );

    this.setState({
      result: resp.data.user_categories,
      total_users: resp.data.total_users_count,
    });
    const result = resp.data.user_categories;
    const total = resp.data.total_users_count;

    this.getData({ result, total });
    this.highChartsRender();
  };

  getData({ result, total }) {
    // const result = this.state.result;
    // const total = this.state.total_users;
    let initial = [];

    result.filter((role) => {
      const percent = (role.total / total) * 100;
      // console.log(role.role_name);
      // console.log(percent);
      let each_object = {
        name: role.role_name,
        y: percent,
      };
      // console.log(each_object);
      // initial.push(each_object);
    });
    // console.log(initial);
    // console.log("got here");
    this.setState({
      data: initial,
    });
  }

  render() {
    console.log(this.state.data);
    // const result = this.state.result;
    // result.filter((role) => {

    // }
    // const result = this.state.result;
    // const total = this.state.total_users;
    // let initial = [];

    // result.filter((role) => {
    //   const percent = role.total / total;
    //   console.log(role.role_name);
    //   console.log(percent);
    //   let each_object = {
    //     name: role.role_name,
    //     y: percent,
    //   };
    //   initial.push(each_object)

    // });

    // this.setState({
    //   series.data : initial
    // })

    // console.log(data);
    return <div id="all-customers" className="mt-5 mb-5 card"></div>;
  }
}

export default AllCustomers;
