import React, { Component } from "react";
import RecentWidget from "./SubComponents/recent-transactions";
import AllCustomers from "./SubComponents/all-customers";

export class stats4 extends Component {
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-8">
            <RecentWidget />
          </div>
          <div className="col-lg-4">
            <AllCustomers />
          </div>
        </div>
      </div>
    );
  }
}

export default stats4;
